import { useEffect, useState } from 'react';

const downloadLinkUrls = {
    'Android': '',
    'iOS': '',
    'Windows Phone': 'https://carzlook.com',
    'unknown': 'https://carzlook.com',
}

const DownloadApp = props => {

    const [downloadLink] = useState(downloadLinkUrls);

    useEffect(() => {
        window.scrollTo(0, 0);

        let t = downloadLink[getMobileOperatingSystem()];

        if (!t) {
            return;
        }

        window.location.href = t;
    }, [downloadLink])


    const getMobileOperatingSystem = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return "Windows Phone";
        }

        if (/android/i.test(userAgent)) {
            return "Android";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iOS";
        }

        return "unknown";
    }

    return null;
}

export default DownloadApp;