import React from 'react';
import FooterCopyright from './FooterCopyright/FooterCopyright';
import FooterBadges from './FooterBadges/FooterBadges';
import FooterBranding from './FooterBranding/FooterBranding';
import FooterAppDownload from './FooterAppDownload/FooterAppDownload';
import FooterLinks from './FooterLinks/FooterLinks';


function footer(props) {
    return (
        <footer>
            <div className="footer-container">
                {/* <FooterBranding /> */}
                <FooterAppDownload />
            </div>
            {/* <FooterLinks /> */}
            {/* <FooterBadges /> */}
            <FooterCopyright />
        </footer>
    );
} 


export default footer;