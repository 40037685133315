import React from 'react';
import { getTranslatedPhrase } from "../../../../shared/util/lang";
import { connect } from "react-redux";
import RealEstateBathroom from '../Assets/realestate-bathroom.svg';
import RealEstateBedroom from '../Assets/realestate-bedroom.svg';

const RealEstatePreviewMeta = (props) => {
    return (
        <>
            <p className="slp-title">{props.title}</p>
            <p className="slp-brand">
                <span>{getTranslatedPhrase(props.userLanguage, 'By')}</span> {props.authorName || props.officeName}
            </p>
            <p className="slp-brand" style={{ marginTop: '.4rem' }}><span
                style={{ display: 'inline-block', marginRight: '.2rem', marginLeft: '.3rem' }}><i
                    className="fa-duotone fa-location-dot" style={{ display: 'inline-block', marginRight: '.3rem' }} /> </span>
                <strong>{props.city}, {props.state}</strong></p>

            {/* {(props.bedrooms || props.bathrooms) && */}
                <div className="slp-sub-price-icons-preview">
                    {props.bedrooms &&
                        <div>
                            <img src={RealEstateBedroom} alt="" /> x&nbsp; <strong>{props.bedrooms}</strong>
                        </div>
                    }

                    {props.bathrooms &&
                        <div>
                            <img src={RealEstateBathroom} alt="" /> x&nbsp; <strong>{props.bathrooms}</strong>
                        </div>
                    }
                </div>
            {/* } */}
        </>
    )
}

const mapStateToProps = (state) => {
    return { userLanguage: state.userLanguage, };
};

export default connect(mapStateToProps)(RealEstatePreviewMeta);