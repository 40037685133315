import React from 'react';
import { useSelector } from 'react-redux';
import { getTranslatedPhrase } from '../../../shared/util/lang';
import { Helmet } from "react-helmet";
import AutoSearch from "../../Home/Components/Auto";
import RealEstateSearch from "../../Home/Components/RealEstate";
import StoreSearch from "../../Home/Components/Store";
import TravelSearch from "../../Home/Components/Travel";
import JobsSearch from "../../Home/Components/Jobs";
import ServicesSearch from "../../Home/Components/Services";
import FoodsSearch from "../../Home/Components/Foods";
import Image from './Assets/image404.svg';


const FourZeroFour = props => {
    let userLanguage = useSelector(state => state.userLanguage);

    return (
        <>
            <div className="layout-background layout-background-404" />
                <div className="main-100 page-404">
        
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>404 | CarzLook</title>
                    <meta name="description" content="Oops! This page doesn't exist on CarzLook website." />
                    <link rel="canonical" href="https://carzlook.com/404" />
                </Helmet>

                <div className="page-404-header">
                    <img src={Image} alt="404 Oops, nothing here." />
                    <h1><strong>404</strong> | {getTranslatedPhrase(userLanguage,'Oops, nothing here...')}</h1>
                    {/* <p>Discover something new using our dedicated category searches below.</p> */}
                </div>


                {/* <div className="new-home">
                    <div className="home-grid">
                        <AutoSearch />
                        <RealEstateSearch />
                        <StoreSearch />
                        <TravelSearch />
                        <JobsSearch />
                        <ServicesSearch />
                    </div>

                    <FoodsSearch />
                </div> */}
            </div>
        </>
    );
}


export default FourZeroFour;