import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import actionType from '../../../../redux-store/action-type';
import { getTranslatedPhrase } from '../../../util/lang';


class footerLinks extends React.Component {
    onClickContact = () => {
        this.props.onLeaveFeedback('feedback');
        this.props.history.push('/contact');
    }

    render() {
        return (<div className="footer-links">
            <div className="footer-links-row">
                <h4>{getTranslatedPhrase(this.props.userLanguage, 'About')}</h4>
                <ul>
                <li><Link to="/about">{getTranslatedPhrase(this.props.userLanguage, 'About')}</Link></li>
                    {/* <li><Link to="/meet-the-team">{getTranslatedPhrase(this.props.userLanguage, 'Meet The Team')}</Link></li> */}
                    {/* <li><Link to="/directory">{getTranslatedPhrase(this.props.userLanguage, 'Directory')}</Link></li> */}
                    <li><Link to="/help">{getTranslatedPhrase(this.props.userLanguage, 'FAQ')}</Link></li>
                    {/* <li><Link to="/careers">{getTranslatedPhrase(this.props.userLanguage, 'Careers')}</Link></li> */}
                    {/* <li><Link to="/press">{getTranslatedPhrase(this.props.userLanguage, 'Press')}</Link></li> */}
                    <li><a href="#contact" onClick={this.onClickContact}>{getTranslatedPhrase(this.props.userLanguage, 'Leave Feedback')}</a></li>
                </ul>
            </div>


            {/* <div className="footer-links-row">
                <h4>{getTranslatedPhrase(this.props.userLanguage, 'Partners')}</h4>
                <ul>
                    <li><Link to="/advertise">{getTranslatedPhrase(this.props.userLanguage, 'Advertise')}</Link></li>
                    <li><Link to="/affiliates">{getTranslatedPhrase(this.props.userLanguage, 'Affiliates')}</Link></li>
                    <li><Link to="/investors">{getTranslatedPhrase(this.props.userLanguage, 'Investors')}</Link></li>
                    <li><Link to="/start-ups">{getTranslatedPhrase(this.props.userLanguage, 'Start-Ups')}</Link></li>
                </ul>
            </div> */}


            {/* <div className="footer-links-row">
                <h4>{getTranslatedPhrase(this.props.userLanguage, 'Buy/Sell')}</h4>
                <ul>
                    <li><Link to={`/store?#top`}>{getTranslatedPhrase(this.props.userLanguage, 'Products')}</Link></li>
                    <li><Link to={`/autos?#top`}>{getTranslatedPhrase(this.props.userLanguage, 'Cars')}</Link></li>
                    <li><Link to={`/realestate?#top`}>{getTranslatedPhrase(this.props.userLanguage, 'Properties')}</Link></li>
                    <li><Link to={`/food?#top`}>{getTranslatedPhrase(this.props.userLanguage, 'Food')}</Link></li>
                    <li><Link to={`/travel?#top`}>{getTranslatedPhrase(this.props.userLanguage, 'Travel Packages')}</Link></li>
                </ul>
            </div> */}


            {/* <div className="footer-links-row">
            <h4>{getTranslatedPhrase(this.props.userLanguage,'Social')}</h4>
            <ul>
                <li><Link to="/blogs">{getTranslatedPhrase(this.props.userLanguage,'Blogs')}</Link></li>
                <li><Link to="/photos">{getTranslatedPhrase(this.props.userLanguage,'Photos')}</Link></li>
                <li><Link to="/videos">{getTranslatedPhrase(this.props.userLanguage,'Videos')}</Link></li>
                <li><Link to="/events">{getTranslatedPhrase(this.props.userLanguage,'Events')}</Link></li>
                <li><Link to="/groups">{getTranslatedPhrase(this.props.userLanguage,'Groups')}</Link></li>
            </ul>
        </div> */}
        </div>
        );
    }
}

const mapStateToFooterLinksProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
    };
};

const mapDispatchTofooterLinksProps = (
    dispatch
) => {
    return {
        pageShouldRefresh: (p) => {
            dispatch({ type: actionType.PAGE_REFRESH, pageRefresh: p });
        },
        onLeaveFeedback: (a) => {
            dispatch({ type: actionType.SELECT_REASON, reason: a })
        }
    };
};

export default connect(mapStateToFooterLinksProps, mapDispatchTofooterLinksProps)(withRouter(footerLinks));