import React, { useEffect, useState } from 'react';
import Toolbar from '../Toolbar/Toolbar';
import Message from './Message';
import moment from 'moment';
import LoadingSvg from '../../../../../shared/uielements/LoadingSpinner/LoadingSvg';
import { hostNameAndPort } from '../../../../../shared/util/vars';
import axios from 'axios';
import _ from 'lodash';

import { useSelector, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { actions } from '../../../../../redux-store/action-type';

const MAX_TIME_CHAT_REFRESH = 5000;
const MESSAGES_PER_LOAD = 20;

function MessageList(props) {
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  const [messageGotSent, setMessageGotSent] = useState(false);
  const [conversationTitle, setConversationTitle] = useState({});
  const [toUserId, setToUserId] = useState('');
  const [arrowButtonClassname, setArrowButtonClassname] = useState('fal fa-arrow-from-right')
  const [refreshChatInterval, setRefreshChatInterval] = useState(0);
  const [toUserIdIsOnline, setToUserIdIsOnline] = useState(0);
  const [refreshIsNotStarted, setRefreshIsNotStarted] = useState(true);
  const [activeConversationLimit, setActiveConversationLimit] = useState(MESSAGES_PER_LOAD);
  const [messagesLimit, setMessagesLimit] = useState([])
  const [lastMessage, setLastMessage] = useState('');

  const history = useHistory();
  let conversationListClassname = useSelector(state => state.conversationListClassname)
  // let loggedInUser = useSelector(state => state.loggedInUser);

  const { activeConversation, loggedInUserChatId } = props;


  useEffect(() => {

    loadMessages();
    scrollMessageArea();
    setActiveConversationLimit(MESSAGES_PER_LOAD);

    return () => {
      setMessages([])
    }

  }, [activeConversation])


  useEffect(() => {
    scrollMessageArea();
  }, [lastMessage])


  useEffect(() => {
    try {

      if (message === '') {
        loadMessages();

        // console.log('scrolling....')

        scrollMessageArea();
        props.onSubmitMessage();
      }
    } catch (err) {
      console.log(err)
    }
  }, [message])




  useEffect(() => {

    if (toUserIdIsOnline && refreshIsNotStarted) {

      setRefreshIsNotStarted(false);

      let tempRefreshChatIniterval = setInterval(() => {
        loadMessages();

        // scrollMessageArea();
      }, MAX_TIME_CHAT_REFRESH)

      setRefreshChatInterval(tempRefreshChatIniterval);
    }

    if (!toUserIdIsOnline) {
      setRefreshIsNotStarted(true);
    }

    return () => clearInterval(refreshChatInterval)
  }, [activeConversation, toUserIdIsOnline, activeConversationLimit])








  const loadMessages = async () => {

    try {

      let result = await axios(`${hostNameAndPort}/api/chat/conversation/${loggedInUserChatId}/${activeConversation}/${activeConversationLimit}`)

      let { data } = result;

      if (!data.error) {

        setLastMessage(data.conversation.lastMessage);
        setMessages(data.conversation.messages);

        setConversationTitle({
          userName: data.conversation.entityName,
          slug: data.conversation.slug,
        })


        if (data.conversation.user1._id === loggedInUserChatId) {
          setToUserId(data.conversation.user2._id)
          setToUserIdIsOnline(data.conversation.user2.isOnline)
        } else {
          setToUserId(data.conversation.user1._id)
          setToUserIdIsOnline(data.conversation.user1.isOnline);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }




  function onClickArrowButton(e) {
    // if (conversationListClassname === 'active') {
    //   conversationListClassname = 'hiding'
    // } else {
    //   conversationListClassname = 'active'
    // }

    // props.toggleHideConversationList(conversationListClassname)

    // if (arrowButtonClassname === 'fal fa-arrow-from-left') {
    //   setArrowButtonClassname('fal fa-arrow-from-right')
    // } else {
    //   setArrowButtonClassname('fal fa-arrow-from-left')
    // }
  }

  const onClickReturnToPage = () => {
    history.goBack();
  }

  const closeChatSidebar = () => {
    conversationListClassname = 'active';
    props.toggleHideConversationList(conversationListClassname)
  }


  const getOldMessagesForCurrentConversation = () => {
    clearInterval(refreshChatInterval)
    setActiveConversationLimit(activeConversationLimit + MESSAGES_PER_LOAD);
    loadMessages();
    setRefreshIsNotStarted(true);
  };


  function onChangeMessage(e) {
    setMessage(e.target.value)
  }

  const onSubmitMessage = async (e) => {
    try {
      e.preventDefault();

     

      let result = await axios({
        url: `${hostNameAndPort}/api/chat/message`,
        method: "post",
        data: {
          content: message,
          fromUserId: loggedInUserChatId,
          conversationId: activeConversation,
          sentAt: new Date(),
          toUserId: toUserId,
        }
      })

      let { data } = result;

      if (!data.error) {
        setMessage('');
      }

      setMessageGotSent(true);

      setTimeout(() => {
        setMessageGotSent(false);
      }, 1000)

    } catch (err) {
      console.log(err);
    }
  }


  const scrollMessageArea = () => {
    setTimeout(() => {
      let messageArea = document.getElementById('message-list-element');
      if (messageArea) {
        messageArea.scrollTop = messageArea.scrollHeight
      }
    }, 500)
  }

  const updateMessages = (conversationId) => {
    // setMessages()
    scrollMessageArea();
  }

  useEffect(() => {
    updateMessages(1);
  }, [])


  function getPhotoForItem({ link, height }) {
    try {
      return new Promise((resolve, reject) => {
        const originalLink = link;
        link = link.replace(/^(https?:)?(\/\/)?((m.|www.|qa.|test.)?carzlook.com|localhost:3000)/, '').split('/');
        let section = link[1];
        let item = link[4];
        if (section === 'autos') section = 'cars';
        // resolve(0);
        let url = hostNameAndPort + `/api/${section}/${item}`;
        axios.get(url)
          .then(result => {
            let { data } = result;
            if (!data.error) {
              let img = new Image();
              img.height = height;
              img.src = data.item.featuredImage;
              let elem = document.createElement('a');
              elem.href = originalLink;
              if (elem.childNodes.length) {
                elem.removeChild(elem.childNodes[0]);
              }
              elem.appendChild(img);
              img.addEventListener('load', e => resolve(elem));
              img.addEventListener('error', () => {
                reject(new Error(`Failed to load image's URL: ${link}`));
              });
            } else {
              let img = new Image();
              img.height = height;
              img.src = 'https://websitediag290.blob.core.windows.net/images/upload_60882c711ca0d90910b3018f438ec64a';
              let elem = document.createElement('a');
              elem.href = originalLink;
              if (elem.childNodes.length) {
                elem.removeChild(elem.childNodes[0]);
              }
              elem.appendChild(img);
              img.addEventListener('load', e => resolve(elem));
              img.addEventListener('error', () => {
                reject(new Error(`Failed to load image's URL: ${link}`));
              });
            }
          })
          .catch(console.log);
      });
    } catch (err) {
      if (process.env.NODE_ENV === 'development') console.log(err)
    }
  }

  const renderMessages = () => {
    let i = 0;

    if (!messages) return;
    let messageCount = messages.length;
    let tempMessages = [];
    // if (!loggedInUser) { loggedInUser = { userId: 'apple' }; }

    while (i < messageCount) {
      let previous = messages[i - 1];
      let current = messages[i];
      let next = messages[i + 1];
      let isMine = current.fromUserId === loggedInUserChatId;
      let currentMoment = moment(current.sentAt);
      let prevBySameAuthor = false;
      let nextBySameAuthor = false;
      let startsSequence = true;
      let endsSequence = true;
      let showTimestamp = true;
      if (previous) {
        let previousMoment = moment(previous.sentAt);
        let previousDuration = moment.duration(currentMoment.diff(previousMoment));
        prevBySameAuthor = previous.fromUserId === current.fromUserId;
        if (prevBySameAuthor && previousDuration.as('hours') < 1) {
          startsSequence = false;
        }
        if (previousDuration.as('hours') < 1) {
          showTimestamp = false;
        }
      }
      if (next) {
        let nextMoment = moment(next.sentAt);
        let nextDuration = moment.duration(nextMoment.diff(currentMoment));
        nextBySameAuthor = next.fromUserId === current.fromUserId;
        if (nextBySameAuthor && nextDuration.as('hours') < 1) {
          endsSequence = false;
        }
      }
      if (/https?:\/\/(m.|www.|qa.|test.)?carzlook.com/g.test(current.content) || /localhost:3000/g.test(current.content)) {
        // ORIGINAL
        // if (/https?:\/\/(m.|www.|qa.|test.)?carzlook.com/g.test(current.content)) {
        let matches = current.content.match(/(https?:\/\/[-.a-zA-Z0-9:(%20)(%26)&'/]*)/g)
        matches = matches.map(v => {
          let partStart = v.lastIndexOf('/') + 1;
          if (partStart === v.length) {
            v = v.replace(/\/+$/g, '');
            partStart = v.lastIndexOf('/') + 1;
          }
          let partEnd = v.lastIndexOf('-');
          if (partEnd < 0 || partEnd < partStart) partEnd = v.length;
          return { link: v, text: v.substring(partStart, partEnd).replace(/-/g, ' ') }
        })
        let parts = current.content.replace(/((https?:\/\/)[-.a-zA-Z0-9:(%20)(%26)&'/]*)/g, '[link]').split('[link]')

        // ORIGINAL
        // let parts = current.content.replace(/(https?:\/\/[-.a-zA-Z0-9/]*)/g, '[link]').split('[link]')
        if (parts.length > matches.length) matches.push({ link: '', text: '' })
        let imageHolderId = 'chat-image-holder-' + Date.now() + Math.random();
        tempMessages.push(
          <Message
            key={i}
            isMine={isMine}
            startsSequence={startsSequence}
            endsSequence={endsSequence}
            showTimestamp={showTimestamp}
            data={{ sentAt: current.sentAt, text: matches.map((v, j) => (<div key={v.link}>{parts[j]} <a href={matches[j].link} target="_blank" rel="noopener noreferrer">{matches[j].text}</a></div>)) }}
          >
            <div className="chatImageContainer" id={imageHolderId}><LoadingSvg active={true} /></div>
          </Message>
        );
        // I put the image inside the bubble
        // tempMessages.push(<div className={['message', (!isMine)?'':'mine'].join(' ')}><div className="bubble-container" id={imageHolderId}></div></div>)
        getPhotoForItem({ link: matches[0].link, height: 200 })
          .then(img => {
            let elem = document.getElementById(imageHolderId);
            if (elem) {
              if (elem.childNodes.length) {
                elem.removeChild(elem.childNodes[0]);
              }
              elem.appendChild(img);
            }
          })
          .catch(console.log);
      } else {
        current.text = current.content;
        tempMessages.push(
          <Message
            key={i}
            isMine={isMine}
            startsSequence={startsSequence}
            endsSequence={endsSequence}
            showTimestamp={showTimestamp}
            data={current}
          />
        );
      }
      // Proceed to the next message.
      i += 1;
    }
    return tempMessages;
  }


  return (
    <div className="message-list">
      <div className="message-list-header">
        {/* when open change right to left  */}
        <button onClick={props.backToList}><i className="fal fa-arrow-left" /></button>
        <Toolbar
          title={conversationTitle.userName || 'Loading...'}
          slug={conversationTitle.slug || ''}
        />
        {/* <button onClick={onClickReturnToPage}><i className="fal fa-times" /></button> */}
      </div>

      <div className="message-list-container scrollable" id="message-list-element" onScroll={(e) => {
        if (e.target.scrollTop === 0) {
          getOldMessagesForCurrentConversation()

        }
      }}>{renderMessages()}</div>

      <div className="compose" id="compose-element" onClick={closeChatSidebar}>
        <form onSubmit={onSubmitMessage}>
          <input
            type="text"
            className="compose-input"
            placeholder="Type a message"
            value={message}
            onChange={onChangeMessage}
          />
          <button disabled={messageGotSent}><i className="fal fa-paper-plane" /></button>
        </form>
      </div>
    </div>
  );
}



const mapDispatchToMessageListProps = dispatch => {
  return {
    // changeMessageStatus: (gotNewMessageFlag) => dispatch({ type: actions.NEW_MESSAGE_FLAG, gotNewMessageFlag }),
    // changeLastMessage: (lastMessage) => dispatch({ type: actions.LAST_MESSAGE, lastMessage }),
    // updateSeenMessages: (seenMessage) => dispatch({ type: actions.UPDATE_SEEN_MESSAGES, seenMessage }),
    // changeActiveConversation: (currentConversation) => dispatch({ type: actions.ACTIVE_CONVERSATION, currentConversation }),
    // updateConversationStatus: (conversationStatus) => dispatch({ type: actions.CONVERSATION_STATUS, conversationStatus }),
    // changeConversationTitle: (conversationTitle) => dispatch({ type: actions.CONVERSATION_TITLE, conversationTitle }),
    // resetMessagesPageProps: (messagesDefaultUser) => dispatch({ type: actionType.MESSAGES_PAGE, messagesDefaultUser, messagesDefaultMessage: '' }),
    toggleHideConversationList: (conversationListClassname) => dispatch({ type: actions.TOGGLE_HIDE_CONVERSATION_LIST, conversationListClassname }),
    // changeConversationOrder: (conversationsHaveChangedOrder) => dispatch({ type: actions.CONVERSATIONS_CHANGED_ORDER, conversationsHaveChangedOrder }),
    // showAlertModal: (u) => dispatch({ type: actionType.ALERT_MODAL, alertModalActive: true, alertModalMessage: u })
  }
}
export default connect(null, mapDispatchToMessageListProps)(MessageList)
