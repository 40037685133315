import React, { useEffect, useState } from 'react';
import ConversationList from './Sidebar/ConversationList';
import MessageList from './Conversations/MessageList';
import { useSelector } from 'react-redux';
import Helmet from 'react-helmet';
import { useHistory, useParams } from 'react-router';



export default function Messenger(props) {

  const [conversationId, setConversationId] = useState('');
  const [loggedInUserChatId, setLoggedInUserChatId] = useState('');
  const [refreshLists, setRefreshLists] = useState(false);
  const [locationKeys, setLocationKeys] = useState([])

  const history = useHistory();
  const params = useParams();
  let conversationListClassname = "";
  // const conversationListClassname = useSelector(state => state.conversationListClassname)


  useEffect(() => {
    document.body.classList.add('chat-body')
    return () => {
      document.body.classList.remove('chat-body')
    };
  }, [])

  // FIX BACK BUTTON
  useEffect(() => {
    return history.listen(location => {
      if (history.action === 'PUSH') {
        setLocationKeys([location.key])
      }

      if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys)
          history.goForward()
        } else {
          setLocationKeys((keys) => [location.key, ...keys])
          history.goBack()
        }
      }
    })
  }, [locationKeys,])


  const listItemClickHandler = (id, loggedInUserChatId) => {
    // console.log('loggedinuserchatid', id, loggedInUserChatId);

    if (!id) {
      return;
    }

    setConversationId(id)
    setLoggedInUserChatId(loggedInUserChatId)
    history.push('/chat/' + id);
  }

  const refreshListsHandler = () => {
    setRefreshLists(true)
  }

  const stopRefreshListsHandler = () => {
    setRefreshLists(false)
  }

  if (conversationId || conversationId !== '') {
    conversationListClassname = 'hasConversation'
  } else {
    conversationListClassname = ''
  }

  const backToListHandler = () => {
    setConversationId('');
    conversationListClassname = '';
    history.push('/chat');
  }

  return (
    <div className="messenger">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Chat | CarzLook</title>
        <meta name="description" content="Sell products, cars, properties & travel packages, post jobs & services, find deals & coupons and connect with customers, friends & family." />
        <link rel="canonical" href={"https://carzlook.com/chat/"} />
      </Helmet>

      <div className={"scrollable sidebar " + conversationListClassname}>
        <ConversationList
          onClickListItem={listItemClickHandler}
          currentConversation={conversationId}
          refreshLists={refreshLists}
          stopRefreshList={stopRefreshListsHandler}
        />
      </div>

      {params && params.conversationId &&
        <div className="content">
          <MessageList activeConversation={conversationId} loggedInUserChatId={loggedInUserChatId} onSubmitMessage={refreshListsHandler} backToList={backToListHandler} />
        </div>
      }

    </div>
  );
}