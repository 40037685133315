import React, { Component } from 'react';
import { connect } from 'react-redux';
import actionType from '../../../../redux-store/action-type';
import { hostNameAndPort } from '../../../util/vars';
import {withRouter} from 'react-router-dom';
import Axios from 'axios';
import { getTranslatedPhrase } from '../../../util/lang';


class AdminPanel extends Component {
    state = {
            waitingForDeleteConfirmation: false,
            deleteOperationConfirmed: false,
        }

    componentDidUpdate() {
        if (this.state.deleteOperationConfirmed === this.props.deleteOperationConfirmed) {
            return;
        }
        if (!this.props.deleteOperationConfirmed) {
            this.setState({ 
                waitingForDeleteConfirmation: false,
                deleteOperationConfirmed: false
            });
            return;
        }
        else { 
            if (!!this.state.waitingForDeleteConfirmation) {
                this.onDeleteConfirmedNow();
                this.setState({ 
                    waitingForDeleteConfirmation: false,
                    deleteOperationConfirmed: false
                });
            }
            this.props.resetDeleteOperationConfirmation();
        }
    }

    onClickDeleteHandler = () => {
        this.setState({ waitingForDeleteConfirmation: true });
        this.props.onShowConfirmDeleteModal();
    }

    onDeleteConfirmedNow = async () => {
        try {
            let options = {
                url: `${hostNameAndPort}/api/${this.props.itemType}/${this.props.itemSlug}`,
                method: 'delete'
            };
            let result = await Axios(options);
            let {data} = result;
            if (!data.error) {
                if (this.props.itemType === 'realestate-old') this.props.history.push('/realestate');
                else if (this.props.itemType === 'cars') this.props.history.push('/autos');
                else this.props.history.push('/'+this.props.itemType);
            }
        } catch(err) {
            alert(getTranslatedPhrase(this.props.userLanguage,'Network error ERRFRADPN01'));
        }
    }

    onClickEditHandler = () => {
        let a = '/';
        let z = this.props.itemType;
        switch (z) {
            case 'blogs':
            case 'photos':
            case 'videos':
            case 'events':
            case 'groups':
            case 'services':
            case 'jobs':
            a = a + z + '/edit-' +z.slice(0,-1);
            break;
            case 'news':
            a = '/news/edit-news';
            break;
            case 'store':
            a = '/store/edit-product';
            break;
            case 'food':
            a = '/food/edit-food-listing';
            break;
            case 'autos':
            a = '/autos/edit-auto-listing';
            break;
            case 'realestate':
            case 'realestate-old':
            a = '/realestate/edit-property';
            break;
            case 'travel':
            a = '/travel/edit-travel';
            break;
            default:
            break;
        }
        if (z === 'cars' || z === 'realestate') 
            this.props.showFollowoneAlert( getTranslatedPhrase(this.props.userLanguage, 'Feature not available for this listing!') )
        else {
            let s = '?q=' + this.props.itemSlug;
            this.props.history.push({ pathname: a, search: s});
        }
    }

    onPromoteItem = (e) => {
        let itemType = this.props.itemType;
        if (itemType === 'realestate-old') itemType = 'realestate';
        if (itemType === 'cars') itemType = 'autos';
        this.props.onShowAdvertiseModalHandler(itemType, this.props.itemId, !!this.props.isFromCarsDB, !!this.props.isFromRealestateDB);
        // if (this.props.itemType !== 'group') {
        //     // this.props.history.push(`/create-ppv-campaign?item=${this.props.itemId}&cat=${this.props.itemType}`);
        // } else {
        //     this.props.showFollowoneAlert(getTranslatedPhrase(this.props.userLanguage,'Feature not available'));
        // }
    }

    render () {
        if (!this.props.userIsAuthorized || (this.props.description && this.props.description === 'Profile Photos')) {
            return null;
        }

        return (
            <div className="main-sidebar_box main-sidebar_box_admin">
                <h4>{getTranslatedPhrase(this.props.userLanguage,'Tools')}</h4>

                <div className="main-sidebar_box_admin_tools">

                    {/* <button>
                        <i className="fal fa-comment"></i>
                        <span>Message Owner</span>
                    </button> */}

                    {/* <button onClick={window.print}>
                        <i className="fal fa-print"></i>
                        <span>{getTranslatedPhrase(this.props.userLanguage,'Print')}</span>
                    </button> */}

                    <button onClick={this.onPromoteItem}>
                        <i className="fal fa-bullhorn" />
                        <span>{getTranslatedPhrase(this.props.userLanguage,'Promote')}</span>
                    </button>

                    {/* <button>
                        <i className="fal fa-share-alt"></i>
                        <span>Share</span>
                    </button> */}

                    {/* <button>
                        <i className="fa-duotone fa-envelope"></i>
                        <span>Email A Friend</span>
                    </button> */}

                    {/* <button>
                        <i className="fal fa-reply-all"></i>
                        <span>Report</span>
                    </button> */}

                    <button onClick={this.onClickEditHandler}>
                        <i className="fal fa-pencil-alt" />
                        <span>{getTranslatedPhrase(this.props.userLanguage,'Edit')}</span>
                    </button>



                    <button onClick={this.onClickDeleteHandler}>
                        <i className="fal fa-trash-alt" />
                        <span>{getTranslatedPhrase(this.props.userLanguage,'Delete')}</span>
                    </button>

                    {/* <button>
                        <i className="fal fa-plus-circle"></i>
                        <span>Add</span>
                    </button>

                    <button>
                        <i className="fal fa-rss"></i>
                        <span>Follow</span>
                    </button> */}
                </div>
            </div>
        );
    }
}

const mapStateToAdminPanelProps = (
    state,
    props
) => {
    return {
        deleteOperationConfirmed: state.deleteOperationConfirmed,
        userLanguage: state.userLanguage
    };
};

const mapDispatchToAdminPanelProps = (
    dispatch
) => {
    return {
        onShowConfirmDeleteModal: () => {
            dispatch({type: actionType.CONFIRM_DELETE_OPERATION_MODAL, confirmDeleteOperationModalActive: true});
        },
        showFollowoneAlert: (u) => {
            dispatch({type: actionType.ALERT_MODAL, alertModalActive: true, alertModalMessage: u });
        },
        resetDeleteOperationConfirmation: () => {
            dispatch({type: actionType.DELETE_OPERATION, deleteOperationConfirmed: false });
        },
        onShowAdvertiseModalHandler: (adItemType, adItemId, adFromCars, adFromRealestate) => {
            dispatch({type: actionType.ADVERTISE_MODAL, advertiseModalActive: true, advertiseItemType: adItemType, advertiseItemId: adItemId, advertiseIsFromCarsDB: adFromCars, advertiseIsFromRealestateDB: adFromRealestate });
        },
    };
};

export default connect(mapStateToAdminPanelProps, mapDispatchToAdminPanelProps)(withRouter(AdminPanel));