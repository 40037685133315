import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const Privacy = props => {

    useEffect(() => {
        window.scrollTo(0,0);
    }, [])

    return (
        <div className="main-100 privacy-policy">

            <Helmet>
                <meta charSet="utf-8" />
                <title>Privacy Policy | CarzLook</title>
                <meta name="description" content="Your privacy is important to us." />
                <link rel="canonical" href="https://carzlook.com/privacy" />
            </Helmet>

                <div className="main-100-header">
                    <h1>Privacy Policy</h1>
                    <p>Your privacy is important to us.</p>
                </div>

                <div className="legal-text">

                    <h4 className="mgb-sm">1. INTRODUCTION</h4>

                    <p>Thank you for your interest in our company, website, mobile applications and our services. This Privacy Policy has a global nature, and it applies both to CarzLook website (carzlook.com) and mobile applications. Your privacy is an essential topic for us. This document (‘Privacy Policy’ or ‘the document’) provides valuable information. We recommend you read this Privacy Policy carefully. Also, you can find additional useful information in our Terms of Use and Cookie Policy. In case of inconsistency between the clauses of this Privacy Policy and any other provisions from Terms of Use, the clauses of this document shall prevail. </p>

                    <p>The purpose of this Privacy Policy is to inform you what personal data we, CarzLook Inc., CarzLook Group Inc., CarzLook Real Estate Inc. (collectively CarzLook, ‘we’ and ‘us’), process (e.g., collect, use, share), why we process it, how we process it, your legal rights, how you can exercise these rights and other essential information according to law. </p>

                    <h4 className="mgb-sm">2. IDENTITY AND CONTACT DETAILS </h4>

                    <p>The controller's identity and contact details: CarzLook Inc., 777 108th AVE NE, Suite 2030, Bellevue WA 98004, US, UBI number 603-465-331 issued by Washington State Business License Services, e-mail support@followone.com. </p>

                    <p>The controller's representative's identity and contact details: Followone Romania SRL, having its registered office in Romania, street Drm. Padurea Neagra, nr. 19-85, bl. Corp 22 A13, et. 2, ap. 21, camera 2, Sector 1, Bucharest, Business Registration Number J40/7727/2018, Cod de Înregistrare Fiscală 39432527, Fiscal Code 39432527, e-mail privacy@followone.com. </p>
                    
                    <h4 className="mgb-sm">3. DEFINITIONS</h4>

                    <p><b>‘GDPR’</b> means Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data (General Data Protection Regulation); </p>

                    <p><b>‘Controller’</b> means Followone Inc., 777 108th AVE NE, Suite 2030, Bellevue WA 98004, US, UBI number 603-465-331 issued by Washington State Business License Services, e-mail support@followone.com; </p>

                    <p><b>‘Controller’s representative’</b> means Followone Romania SRL, having its registered office in Romania, street Drm. Padurea Neagra, nr. 19-85, bl. Corp 22 A13, et. 2, ap. 21, camera 2, Sector 1, Bucharest, Business Registration Number J40/7727/2018, Cod de Înregistrare Fiscală 39432527, Fiscal Code 39432527, e-mail privacy@followone.com; </p>

                    <p><b>‘Data Subject’</b> means any identified or identifiable natural person whose data is processed by us, such as clients, potential clients or website visitors. According to GDPR, an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person; </p>

                    <p><b>‘Personal Data’</b> means any information relating to a data subject; </p>

                    <p><b>‘Services’</b> means the services provided by CarzLook as described on the website and regulated by Terms of Use. </p>

                    <p><b>‘Site’</b> or <b>‘Website’</b> means CarzLook website with the domain carzlook.com’;</p>

                    <p><b>‘Application’</b> or <b>‘Mobile Application’</b> means CarzLook mobile application;</p>

                    <p><b>‘Data Protection Laws’</b> means all data protection regulations including, where applicable, EU Data Protection Law and non-EU Data Protection Laws;</p>

                    <p><b>‘EU Data Protection Law’</b> means all data protection laws and regulations applicable to Europe, including (I) Regulation 2016/679 of the European Parliament and of the Council on the protection of natural persons with regard to the processing of personal data and on the free movement of such data (General Data Protection Regulation) (" GDPR "); (ii) Directive 2002/58/EC concerning the processing of personal data and the protection of privacy in the electronic communications sector; (iii) data protection laws of EU member states;</p>

                    <p><b>‘Processing’</b> means any operation or set of operations which is performed on personal data or on sets of personal data, whether or not by automated means, such as collection, recording, organisation, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction;</p>

                    <p><b>‘Consent’</b> of the data subject means any freely given, specific, informed and unambiguous indication of the data subject's wishes by which he or she, by a statement or by a clear affirmative action, signifies agreement to the processing of personal data relating to him or her.</p>

                    <p>Other terms used in this document have the meaning given by the GDPR and other applicable legal provisions.</p>

                    <h4 className="mgb-sm">4. OTHER SERVICES</h4>

                    <p>This Privacy Policy does not cover other third-party applications and websites that you can reach by accessing links on our website or applications. This goes beyond our control. We encourage you to review the privacy policy on any website and application before you provide your data.</p>

                    <h4 className="mgb-sm">5. OUR COMMITMENT </h4>

                    <p>Protecting your personal information is very important to us. That is why we are committed to respecting Data Privacy Laws and the following principles: </p>

                    <p className="mgb-0"><i>✓ Lawfulness, fairness and transparency</i></p>

                    <p>We process your data legally and correctly. We are always transparent about the information we use, and you are informed accordingly.  </p>

                    <p className="mgb-0"><i>✓ You are in control </i></p>

                    <p>Within the law limits, we offer you the opportunity to review, modify, delete personal data you have shared with us and exercise your other rights. Please refer to sections 11, 12 and 14 of this document for more information.</p>

                    <p className="mgb-0"><i>✓ Data integrity and purpose limitation </i></p>

                    <p>We use the data only for the purposes described at the time of collection or for new purposes compatible with the original ones. In all cases, our purposes are compatible with the law. We take reasonable steps to ensure that personal data is accurate, complete and up-to-date. </p>

                    <p className="mgb-0"><i>✓ Security </i></p>

                    <p>We have implemented reasonable security measures for the protection of personal data. However, please note that no website, application or internet connection is completely secure.</p>

                    <h4 className="mgb-sm">6. CHANGES</h4>
                    
                    <p>We may change this Privacy Policy at any time. All updates, information, and changes to this Policy are effective immediately after notification or at a later date specified in the notice. We use the following methods of notification: displaying the new Privacy Policy on the website/application or sending an e-mail to the user registered. </p>

                    <h4 className="mgb-sm">7. HOW DO WE COLLECT PERSONAL DATA?</h4>

                    <p>We collect personal data in two ways: </p>

                    <p>a) When you give us personal data, or your permission to obtain it</p>

                    <p>When you sign up for or use our products, your information is voluntarily given to us. This can include your name, profile photo, comments, likes, email address, and any other information you provide us. If you’re using our site or application on your mobile device, you can also choose to provide us with your location data. You also may give us permission to access your information through other services. For example, you may link your Facebook or Twitter account, which allows us to obtain information from those accounts (e.g., your friends or contacts). The information we obtain often depends on your settings or their privacy policies.</p>

                    <p>b) We receive technical information when you use our Services or use websites or apps that have features from this site</p>

                    <p>Certain information almost always gets created and recorded automatically whenever you use a website, a mobile app, or other Internet services. The same is true when using our Services. Here are some examples of the types of information we collect: Log Data, Cookie Data and Device Information. You can read more about this information collection in the next section or in our Cookie Policy. </p>

                    <h4 className="mgb-sm">8. WHAT PERSONAL DATA DO WE COLLECT? </h4>

                    <p className="mgb-0"><b>Name, e-mail, username, password </b></p>
                    <p className="mgb-0">Legal basis: this information is necessary for the performance of a contract (e.g., creating an account, accessing the Services) </p>
                    <p>Retention period: this information will be held until the data subject deletes his or her account. </p>

                    <p className="mgb-0"><b>Age/Date of birth </b></p>
                    <p className="mgb-0">Legal basis: the processing is necessary for compliance with the legal obligations (e.g., data protection laws, e-commerce laws) of verifying the age of our visitors before creating an account. </p>
                    <p>Retention period: this information will be held until the data subject deletes his or her account. </p>

                    <p className="mgb-0"><b>Address (e.g., City, Country, State) </b></p>
                    <p className="mgb-0">Legal basis: legitimate interest. </p>
                    <p>Retention period: this information will be held no longer than three years after the data subject deleted his or her account. </p>

                    <p className="mgb-0"><b>Real-time location (e.g., City, Country, State): </b></p>
                    <p className="mgb-0">Legal basis: consent.</p>
                    <p>Retention period: no retention period.</p>

                    <p className="mgb-0"><b>Profile photo</b></p>
                    <p className="mgb-0">Data subjects can upload a profile photo. </p>
                    <p className="mgb-0">Legal basis: consent.</p>
                    <p>Retention period: until the data subject deletes his or her profile photo or deletes his or her account.</p>

                    <p className="mgb-0"><b>Social media accounts</b></p>
                    <p className="mgb-0">Legal basis: consent.  </p>
                    <p>Retention period: this information will be held until the data subject deletes his or her account. </p>

                    <p className="mgb-0"><b>Log Data:</b></p>
                    <p className="mgb-0">This log data may include your Internet Protocol address, address of the web pages you visited with features from this site, browser type, settings, date and time of your request, how you used this website, and cookie data.</p>
                    <p className="mgb-0">Legal basis: legitimate interest (e.g., security, fraud prevention, identifying possible criminal acts)</p>
                    <p>Retention period: please refer to the Cookie Policy. </p>

                    <p className="mgb-0"><b>Cookie Data: </b></p>
                    <p className="mgb-0">Depending on how our products are accessed, we may use “cookies” (a small text file sent by your computer each time you visit our website, unique to your account or browser) or similar technologies to record log data. When using cookies, we may use ‘session’ cookies (that last until you close your browser) or ‘persistent’ cookies (that last until you or your browser delete them). For example, we may use cookies to store your language preferences or other settings, so you don’t have to set them up every time you visit this site. Some of the cookies we use are associated with your account (including personal information about you, such as the email address you gave us), and other cookies are not.</p>
                    <p className="mgb-0">Legal basis: please refer to the Cookie Policy.</p>
                    <p>Retention period: please refer to the Cookie Policy. </p>

                    <p className="mgb-0"><b>Device information </b></p>
                    <p className="mgb-0">In addition to log data, we may also collect information about the device that is using our site. This may include information about what type of device it is, what operating system it’s using, device settings, unique device identifiers, and crash data. Whether we collect some or all of this information depends on what type of device is being used and its settings. Different types of information are available depending on whether a Mac, PC, iPhone, or Android device is utilised. To learn more about what information your device makes available to us, check the policies of your device manufacturer or software provider.</p>
                    <p className="mgb-0">Legal basis: please refer to the Cookie Policy.</p>
                    <p>Retention period: please refer to the Cookie Policy.</p>

                    <p>In addition to the information indicated above, we may collect the following information, depending on the circumstances:  </p>
                    
                    <ul className="mgb-sm">
                        <li>Information provided when filling in forms or questionnaires;</li>
                        <li>The content of messages sent through messaging and e-mail systems.</li>
                    </ul>

                    <p>If you make purchases through our partners, certain payment information (card data) will be collected in certain situations, but our partners will store it in a way that we cannot read and cannot access that data. </p>

                    <h4 className="mgb-sm">9. PURPOSES. HOW DO WE USE YOUR DATA?  </h4>

                    <p>We process personal data for the following purposes:</p>

                    <ul className="mgb-sm">
                        <li>To answer questions and requests and provide support to you;</li>
                        <li>For marketing purposes; </li>
                        <li>To offer and improve the services we offer;</li>
                        <li>To diagnose or remedy technical problems;</li>
                        <li>To protect us from cyberattacks;</li>
                        <li>For account creation and maintenance;</li>
                        <li>To comply with the laws and regulations; </li>
                        <li>In the unlikely event of a dispute, for the defending or exercising of a right in the court.</li>
                        <li>For suggesting content you might like. For example, if you’ve indicated that you’re interested in cooking or visited recipe websites that have features from this site, we may suggest food-related content or people that we think you might like;</li>
                        <li>For showing you ads you might be interested in.</li>
                        <li>For sending you updates (such as when specific activity, like shares or comments), newsletters, marketing materials, and other information that may be of interest to you. Depending on your email notification settings, we may send you weekly updates that include posts you may like. You can decide to stop getting these updates by updating your account settings (or through other settings we may provide).</li>
                        <li>For helping your friends and contacts find you on this site. If you sign up using a Facebook account, we may help your Facebook friends find your account on this site when they first sign up. Or, we may allow people to search for your account using your email address.</li>
                    </ul>

                    <p>Please note that even if we are profiling you for showing content and ads you might be interested in, this profiling does not produce legal effects concerning you or significantly affects you. In any case, you have the right to object to this profiling. If you want to object, please send us an e-mail at privacy@followone.com. </p>
                   
                    <h4 className="mgb-sm">10. DATA TRANSFERS</h4>

                    <p>In compliance with applicable law, we may disclose your data to business partners or other third parties. We are constantly making reasonable efforts to ensure that these third parties have adequate protection and security measures. We have contractual terms with these third parties to protect your data. We will ensure that any transfer is legitimate under the law in these situations.  </p>

                    <p>For example, we could provide your data to other companies, such as IT services providers (cloud, hosting) or telecommunications, accounting, legal services, and other third parties with which we have a contractual relationship. These third parties are selected with particular care so that your data is processed only for the purposes we indicate and according to security standards. </p>

                    <p>Please note that when you post public updates, anyone can view them. You may also provide us with profile page information that anyone can view. The other limited instances where we may share your personal information include:</p>

                    <p>a. When we have your consent. This includes sharing information with other services (like Facebook or Twitter) when you’ve chosen to link to your account to those services or publish your activity to them. For example, you can choose to publish your posts on Facebook or Twitter.</p>

                    <p>b. If we believe that disclosure is necessary to comply with a law, regulation or legal request; to protect the safety, rights, or property of the public, any person; or to detect, prevent, or otherwise address fraud, security, or technical issues.</p>

                    <p>c. We may engage in a merger, acquisition, bankruptcy, dissolution, reorganisation, or similar transaction or proceeding that involves transferring the information described in this Privacy Policy.</p>

                    <p>If you are a European resident, please note that we transfer your data outside the European Economic Area (EEA) because the controller and some of our service providers are located outside the EEA. According to GDPR, the transfer of personal data to a third state can only occur if the state to which the transfer is intended ensures an adequate level of protection.</p>

                    <p>The transfer of data to a state whose legislation does not provide for a level of protection at least equal to that provided by the General Data Protection Regulation is only possible if there are enough safeguards with regard to the protection of the fundamental rights of data subjects. </p>

                    <p>Every time we transfer your data outside the EEA, we will make sure that there is a similar level of protection through one of the following safeguard mechanisms:</p>

                    <ul className="mgb-sm">
                        <li>We will transfer your data to countries where the European Commission has demonstrated that they provide an adequate level of data protection. For more details, <a href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_en" target="_blank">click here</a>;</li>
                        <li>We will use standard contractual clauses provided and approved by the European Commission to ensure a high level of data protection. For more details, <a href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en" target="_blank">click here</a>.</li>
                    </ul>

                    <h4 className="mgb-sm">11. DIRECT MARKETING</h4>
                    
                    <p>We may use, according to data protection laws, direct marketing technologies using the collected information about you. We are now sending business e-mail messages (e-mail marketing) to those who have given prior consent. You may object to direct marketing and/or withdraw your consent at any time by following the opt-out instructions from each e-mail (‘unsubscribe’ or by submitting a request to do so on privacy@followone.com. </p>

                    <h4 className="mgb-sm">12. YOUR RIGHTS </h4>

                    <p>Your rights under data protection laws (i.e. GDPR) are as follows:</p>

                    <p>(a) The right to be informed about the processing of your data. </p>

                    <p>(b) The right of access to data. You have the right to obtain a confirmation from us that personal data concerning you are being processed or not, and if so, you have the right to get access to your data and other relevant information.</p>

                    <p>(c) The right to rectify inaccurate or incomplete data. You have the right to obtain from us, without undue delay, the rectification of inaccurate data concerning you.</p>

                    <p>(d) The right to erasure (the right to be forgotten). In some circumstances described in Article 17 GDPR, you have the right to request and obtain the deletion of personal data. You can also delete your account from Followone’s service interface.</p>

                    <p>(e) The right to restrict processing. In the cases referred to in article 18 of the GDPR, you have the right to request and obtain the restriction of the processing.</p>

                    <p>(f) The right to transfer the data to another controller (right to portability) according to article 19 GDPR. </p>

                    <p>(g) The right to object to the processing of data. In the cases referred to in Article 21 of the GDPR, you have the right to object to data processing.</p>

                    <p>(h) The right to not be subject to a decision based solely on automated processing, including profiling.</p>

                    <p>(i) The right to take legal action (to go to court) for the defence of your rights and interests.</p>

                    <p>(j) The right to lodge a complaint with a Supervisory Authority. </p>

                    <table>
                        <tbody>
                            <tr>
                                <td>Name</td>
                                <td>National Authority for the Supervision of the Processing of Personal Data</td>
                            </tr>

                            <tr>
                                <td>Address</td>
                                <td>B-dul G-ral. Gheorghe Magheru nr. 28-30, Sector 1, postal code 010336, Bucharest, Romania</td>
                            </tr>

                            <tr>
                                <td>Telephone</td>
                                <td>+40.318.059.211 or +40.318.059.212</td>
                            </tr>

                            <tr>
                                <td>E-mail</td>
                                <td>anspdcp@dataprotection.ro</td>
                            </tr>
                        </tbody>
                    </table>

                    <p>Please note that:</p>

                    <p>(1) You may withdraw your consent for direct marketing at any time by following the unsubscribe instructions in each e-mail. </p>

                    <p>(2) If you wish to exercise your rights, you may do so by sending a written request, signed and dated, to the e-mail address: privacy@followone.com. </p>

                    <p>(3) The rights listed above are not absolute. There are exceptions, which is why each request received will be examined to decide whether it is justified or not. In so far as the request is well-founded, we will facilitate the exercise of your rights. If the request is unfounded, we will reject it. In such a case, we will inform you of the reasons for the refusal, and we will tell you that you have the right to lodge a complaint with the Supervisory Authority and the right to take legal action (to go to court).</p>

                    <p>(4) We will try to answer the request within one month. However, the time limit may be extended in the light of different aspects, such as the complexity of the request, the large number of applications received or the impossibility to identify you within a reasonable time.</p>

                    <p>(5) If we do our best, but we cannot identify you, and you do not provide us with any additional information to help us identify you, we are not obliged to answer the request.</p>

                    <h4 className="mgb-sm">13. CHILDREN’S INFORMATION </h4>

                    <p>Children 13 or under are not permitted to use our website. If you learn that your child has provided us with personal information without your consent, do not hesitate to contact us at support@followone.com. </p>

                    <h4 className="mgb-sm">14. CONTACT </h4>

                    <p>If you have any questions or concerns about the processing of your data, if you want to exercise your legal rights or if you have any other privacy concerns, you can contact us at privacy@followone.com. </p>
                </div>

        </div>
    );
}

export default Privacy;