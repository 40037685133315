import React from 'react';
import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    PinterestShareButton,
    ViberShareButton,
    // EmailShareButton,
} from 'react-share';


//<button onClick={props.onShareOnFollowone || defaultClick}><span className="fo-followone">O</span></button>
//   <EmailShareButton url={props.shareLink}>
//   <button><i className="far fa-envelope"></i></button>
//   </EmailShareButton>

function ShareModal(props) {
    const defaultClick = () => null;
    const { shareLink, shareImage, onClickShare } = props;

    function onShareCopyLink() {
        function sleep1(timeout) {
            return new Promise(resolve => {
                setTimeout(() => {
                    resolve(0);
                }, timeout);
            })
        }

        function sleep2(timeout, parent, element) {
            return new Promise(resolve => {
                setTimeout(() => {
                    parent.removeChild(element);
                    resolve(0);
                }, timeout);
            })
        }

        let parent = document.querySelector('.engage_buttons_share_modal');
        let el = document.createElement('input')
        el.className = 'link-copied';
        el.value = shareLink;
        el.setAttribute('readonly', '');
        el.style = { position: 'absolute', left: '-9999px' };
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');

        let el2 = document.createElement('div')
        el2.className = 'link-copied';
        el2.innerText = 'Link Copied!';
        el2.setAttribute('readonly', '');
        parent.appendChild(el2);

        document.body.removeChild(el);

        sleep1(100);
        sleep2(2500, parent, el2);

        props.onShareCopyLink();
    }

    if (!props.active) {
        return null;
    }
    return (
        <div className="engage_buttons_share_modal">
            {/*<div>*/}
            {/*    <a href=""><i className="fas fa-comment-alt"></i></a>*/}
            {/*</div>*/}
            <FacebookShareButton url={shareLink} >
                <button onClick={onClickShare}><i className="fab fa-facebook-f" /></button>
            </FacebookShareButton>
            <TwitterShareButton url={shareLink} >
                <button onClick={onClickShare}><i className="fab fa-twitter" /></button>
            </TwitterShareButton>
            <PinterestShareButton url={shareLink} media={shareImage}>
                <button onClick={onClickShare}><i className="fab fa-pinterest-p" /></button>
            </PinterestShareButton>
            <WhatsappShareButton url={shareLink}>
                <button onClick={onClickShare}><i className="fab fa-whatsapp" /></button>
            </WhatsappShareButton>





            <div className="hide-on-m">
                <ViberShareButton url={shareLink}>
                    <button onClick={onClickShare}><i className="fab fa-viber"></i></button>
                </ViberShareButton>
            </div>

            <div>
                <a href={`https://t.me/share/url?url=${shareLink}&text=${'Checkout this listing from CarzLook'}`} target="_blank" rel="noopener noreferrer"><i className="fab fa-telegram-plane"></i></a>
            </div>

            <div>
                <button onClick={onShareCopyLink || defaultClick}><i className="fal fa-link"></i></button>
            </div>

        </div>
    );
}

export default ShareModal;
