import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { hostNameAndPort } from '../../../../shared/util/vars';
import moment from 'moment';
import Axios from 'axios';
import { connect } from 'react-redux';
import actionType from '../../../../redux-store/action-type';
import { getTranslatedPhrase } from '../../../../shared/util/lang';
import Specials from '../../../../shared/components/Advertising/Specials/Specials';
import Helmet from 'react-helmet';
import { trimTitle } from '../../../../shared/util/string';
import LoadingSvg from "../../../../shared/uielements/LoadingSpinner/LoadingSvg";

class Notifications extends Component {
    state = {
        activeTabs: {
            'comments': true,
            'likes': false,
            'shares': false,
            'reviews': false,
            'paidNotifications': false,
        },
        likesList: [],
        commentsList: [],
        reviewsList: [],
        sharesList: [],
        specialsCount: 0,
        affiliateId: '',
        accountType: '',
        followRequestsList: [],
        loaded: true,
        loadedhistory: true,
        firstTime: true,
        clearedLikes: false,
        clearedComments: false,
        clearedReviews: false,
    }

    componentDidMount() {
        // this.getNotificationsDataFromServer();
        // this.getNotificationsHistory();

        setTimeout(() => {
            this.checkAllNotificationOffInclusive();
        }, 10000)

        setTimeout(() => {
            this.getAccountType();
            this.getFollowRequestsDataFromServer();
        }, 500);

        let { hash } = this.props.location;

        if (hash) {
            hash = decodeURI(hash.slice(1));
            this.setActiveTab(hash);
        }

        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 500)
    }

    componentDidUpdate() {
        if (this.props.userIsAuthenticated && !this.state.accountType && this.state.firstTime) {
            this.setState({ firstTime: false });
            this.getAccountType();
            this.getNotificationsDataFromServer();
            this.getNotificationsHistory();
            let { hash } = this.props.location;
            if (hash) {
                hash = decodeURI(hash.slice(1));
                this.setActiveTab(hash);
            }
        }
    }


    getFollowRequestsDataFromServer = () => {
        let url = `${hostNameAndPort}/api/user/follow-requests`;
        Axios(url)
            .then(result => {
                let { data } = result;
                if (!data.error) {
                    this.setState({ followRequestsList: data.followRequestsList });
                } else this.props.history.push('/');
            })
            .catch(err => {
                this.props.history.push('/');
            });
    }

    onApproveFollowRequest = (_userId) => {
        let _newList = this.state.followRequestsList;
        _newList = _newList.filter(v => (v.userId !== _userId));
        this.setState({ followRequestsList: _newList });

        let _data = new FormData();
        _data.append('fromUserId', _userId);
        _data.append('confirmed', true);

        let options = {
            url: `${hostNameAndPort}/api/user/follow`,
            method: 'post',
            data: _data
        };
        Axios(options)
            .then(result => {
                let { data } = result;
                if (!data.error) {
                    // No error
                }
            })
            .catch(err => {
                // Network error
            });
    }

    onRemoveFollowRequest = (_userId) => {
        let _newList = this.state.followRequestsList;
        _newList = _newList.filter(v => (v.userId !== _userId));
        this.setState({ followRequestsList: _newList });

        let _data = new FormData();
        _data.append('fromUserId', _userId);
        _data.append('confirmed', true);

        let options = {
            url: `${hostNameAndPort}/api/user/remove-follow-request`,
            method: 'post',
            data: _data
        };
        Axios(options)
            .then(result => {
                let { data } = result;
                if (!data.error) {
                    // No error
                }
            })
            .catch(err => {
                // Network error
            });
    }

    getRightEnding = (n) => {
        if (n === 1) {
            return 'Follow Request';
        } else {
            return 'Follow Requests';
        }
    }

    getAccountType = async () => {
        let url = `${hostNameAndPort}/api/user/affiliate-info`;
        try {
            let result = await Axios(url);
            let { data } = result;
            if (!data.error) {
                if (!!data.user.affiliateId && data.user.accountType === 'personal') {
                    let { hash } = this.props.location;
                    if (!hash || hash === '#paidNotifications') {
                        data.user.activeTabs = {
                            'comments': false,
                            'likes': false,
                            'shares': false,
                            'reviews': false,
                            'paidNotifications': true,
                        };
                    }
                }
                this.setState(data.user);
            } else this.props.history.push('/');
        } catch (err) {
            this.props.history.push('/');
        }
    }


    getNotificationsHistory = () => {
        let url = `${hostNameAndPort}/api/user/notification-history/30`;

        if (this.state.loadedhistory) this.setState({ loadedhistory: false });
        Axios(url)
            .then(result => {
                let { data } = result;
                if (!data.error) {

                    // console.log(data.notificationsList);

                    data.commentsList = data.notificationsList
                        .filter(v => (v.activityType === 'comment'))
                        .map(v => {
                            if (v.itemAuthorId === this.props.loggedInUser.userId) return {
                                ...v,
                                snippet: trimTitle(v.title, 50),
                                url: `/${v.websiteSection}/${v.category}/${v.subcategory || 'all'}/${v.slug}`
                            };
                            return {
                                ...v,
                                snippet: trimTitle(v.comment, 50),
                                url: `/${v.websiteSection}/${v.category}/${v.subcategory || 'all'}/${v.slug}`,
                                itemType: 'comments'
                            };
                        });
                    // data.notificationList = data.notificationsList.filter(v => (['like', 'review', 'share'].indexOf( v.activityType ) >= 0 ));
                    data.likesList = data.notificationsList
                        .filter(v => (v.activityType === 'like'))
                        .map(v => {
                            if (v.itemType !== 'comments') {
                                if (v.itemType === 'photos' && v.slug.indexOf('?') < 0)
                                    return {
                                        ...v,
                                        snippet: trimTitle(v.title, 50),
                                        url: `/${v.websiteSection}/${v.category}/${v.subcategory || 'all'}/${v.slug}`,
                                        itemType: 'gallery'
                                    };
                                else return {
                                    ...v,
                                    snippet: trimTitle(v.title, 50),
                                    url: `/${v.websiteSection}/${v.category}/${v.subcategory || 'all'}/${v.slug}`
                                };
                            }
                            return {
                                ...v,
                                snippet: trimTitle(v.comment, 50),
                                url: `/${v.websiteSection}/${v.category}/${v.subcategory || 'all'}/${v.slug}`
                            };
                        });
                    data.sharesList = data.notificationsList.filter(v => (v.activityType === 'share'));
                    data.reviewsList = data.notificationsList.filter(v => (v.activityType === 'review'));
                    data.reviewsList = data.reviewsList.map(v => {
                        if (v.itemType !== 'users') return {
                            ...v,
                            snippet: trimTitle(v.title, 50),
                            url: `/${v.itemType}/${v.category}/${v.subcategory || 'all'}/${v.slug}`
                        };
                        else return {
                            ...v,
                            snippet: 'See the review',
                            url: '/' + this.props.loggedInUser.slug
                        };
                    });
                    data.specialsCount = data.notificationsList.filter(v => (v.activityType === 'cashin')).length;
                    delete data.notificationsList;
                    data.loadedhistory = true;

                    this.setState(data);
                }
            })
            .catch(err => {
                // Network error
            });
    }


    getNotificationsDataFromServer = () => {
        let url = `${hostNameAndPort}/api/user/notifications`;

        if (this.state.loaded) this.setState({ loaded: false });
        Axios(url)
            .then(result => {
                let { data } = result;
                if (!data.error) {

                    data.specialsCount = data.notificationsList.filter(v => (v.activityType === 'cashin')).length;
                    delete data.notificationsList;
                    data.loaded = true;

                    this.setState(data);
                }
            })
            .catch(err => {
                // Network error
            });
    }

    onClearLikes = async () => {
        let n = this.state.likesList.length;
        let result = await this.checkAllNotificationOff(n, 'like');

        setTimeout(() => {
            this.setState({ clearedLikes: true });
        }, 4000)


        // if (result) this.setState({ likesList: [] });
    }

    onClearShares = async () => {
        let n = this.state.sharesList.length;
        let result = await this.checkAllNotificationOff(n, 'share');
        // if (result) this.setState({ sharesList: [] });
    }

    onClearReviews = async () => {
        let n = this.state.reviewsList.length;
        let result = await this.checkAllNotificationOff(n, 'review');


        setTimeout(() => {
            this.setState({ clearedReviews: true });
        }, 4000)
        // if (result) this.setState({ reviewsList: [] });
    }

    onClearComments = async () => {
        let n = this.state.commentsList.length;
        let result = await this.checkAllNotificationOff(n, 'comment');


        setTimeout(() => {
            this.setState({ clearedComments: true });
        }, 4000)

        // if (result) this.setState({ commentsList: [] });
    }

    checkAllNotificationOff = async (n, item) => {
        try {

            console.log('all')

            let options = `${hostNameAndPort}/api/user/notification/check/${item}`;
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                return true;
            } else return false;
        } catch (err) {
            return false;
        }
    }

    checkAllNotificationOffInclusive = async (n, item) => {
        try {

            console.log('all inclusive')

            let options = `${hostNameAndPort}/api/user/notification/checkall/`;
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                return true;
            } else return false;
        } catch (err) {
            return false;
        }
    }

    getItemType = (k) => {
        switch (k) {
            case 'store': return 'product';
            case 'cars':
            case 'autos': return 'auto listing';
            case 'travel': return 'travel package';
            case 'realestate-new':
            case 'realestate': return 'property';
            case 'jobs': return 'job posting';
            case 'groups': return 'post on the group';
            case 'users': return 'business';
            case 'food': return 'food item';
            case 'gallery': return 'photo gallery';
            default:
                return k.slice(0, k.length - 1);
        }
    }

    setActiveTab = (ct) => {
        let a = {
            'comments': false,
            'likes': false,
            'shares': false,
            'reviews': false,
            'paidNotifications': false,
            'followrequests': false,
        };
        if (!ct) ct = 'comments';
        a[ct] = true;

        switch (ct) {
            case 'comments':
                setTimeout(() => {
                    this.onClearComments();
                }, 2000)
                break;
            case 'likes':
                setTimeout(() => {
                    this.onClearLikes();
                }, 2000)
                break;
            case 'shares':
                setTimeout(() => {
                    this.onClearShares();
                }, 2000)
                break;
            case 'reviews':
                setTimeout(() => {
                    this.onClearReviews();
                }, 2000)
                break;
            default:
                break;
        }

        this.props.history.push(this.props.match.url + '#' + ct);
        this.setState({ activeTabs: a });
    }

    getRightTitle(n, s) {
        if (this.state.clearedComments && s === 'comment') {
            return 'No New Comments';
        }

        if (this.state.clearedLikes && s === 'like') {
            return 'No New Likes';
        }

        if (this.state.clearedReviews && s === 'review') {
            return 'No New Reviews';
        }

        if (n === 1) return '1 new ' + s;
        else return n + ' new ' + s + 's';
    }

    render() {

        const addDefaultSingleSrc = (ev) => {
            ev.target.src = 'https://websitediag290.blob.core.windows.net/images/uploads/default/default-profile-image.jpg';
        }

        return (<>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Notifications | CarzLook</title>
                <meta name="description" content="Sell products, cars, properties & travel packages, post jobs & services, find deals & coupons and connect with customers, friends & family." />
                <link rel="canonical" href={"https://carzlook.com/notifications"} />
            </Helmet>

            <div className="layout-background layout-background-notifications-menu" />

            <div className="settings-menu">
                <h2>{getTranslatedPhrase(this.props.userLanguage, 'Notifications')}</h2>

                <div className="settings-layout">

                    <div className="settings-menu-list">

                        {!!this.state.affiliateId && (
                            <button className={!this.state.activeTabs['paidNotifications'] ? '' : 'active'} onClick={(e) => this.setActiveTab('paidNotifications')}>
                                <i className="fal fa-dollar-sign" />
                                <span>{this.state.specialsCount} {getTranslatedPhrase(this.props.userLanguage, 'Ca$hin Ads')}</span>
                            </button>)}

                        <button className={!this.state.activeTabs['followrequests'] ? '' : 'active'} onClick={(e) => this.setActiveTab('followrequests')}>
                            <i className="fal fa-users" />
                            <span>{this.state.followRequestsList.length} {getTranslatedPhrase(this.props.userLanguage, 'Follow Requests')}</span>
                        </button>



                        <button className={!this.state.activeTabs['comments'] ? '' : 'active'} onClick={(e) => this.setActiveTab('comments')}>

                            {this.state.commentsList.filter(v => v.checked === false).length > 0 && !this.state.clearedComments ? <i className="fal fas-comment-alt" /> : <i className="fal fa-comment-alt" />}

                            <span>
                                <LoadingSvg active={!this.state.loadedhistory} />
                                {this.state.loadedhistory && !this.state.clearedComments ? this.state.commentsList.filter(v => v.checked === false).length : !this.state.loadedhistory ? '' : 0}&nbsp;
                                {getTranslatedPhrase(this.props.userLanguage, 'Comments')}
                            </span>
                        </button>



                        <button className={!this.state.activeTabs['likes'] ? '' : 'active'} onClick={(e) => this.setActiveTab('likes')}>

                            {this.state.likesList.filter(v => v.checked === false).length > 0 && !this.state.clearedLikes ? <i className="fas fa-heart" /> : <i className="fal fa-heart" />}
                            <span>
                                <LoadingSvg active={!this.state.loadedhistory} />
                                {this.state.loadedhistory && !this.state.clearedLikes ? this.state.likesList.filter(v => v.checked === false).length : !this.state.loadedhistory ? '' : 0}&nbsp;
                                {getTranslatedPhrase(this.props.userLanguage, 'Likes')}
                            </span>
                        </button>



                        <button className={!this.state.activeTabs['reviews'] ? '' : 'active'} onClick={(e) => this.setActiveTab('reviews')}>

                            {this.state.reviewsList.filter(v => v.checked === false).length > 0 && !this.state.clearedReviews ? <i className="fas fa-star" /> : <i className="fal fa-star" />}
                            <span>
                                <LoadingSvg active={!this.state.loadedhistory} />
                                {this.state.loadedhistory && !this.state.clearedReviews ? this.state.reviewsList.filter(v => v.checked === false).length : !this.state.loadedhistory ? '' : 0}&nbsp;
                                {getTranslatedPhrase(this.props.userLanguage, 'Reviews')}
                            </span>
                        </button>

                        <Link to="" className="empty-space-mobile" />
                    </div>

                    <div className="settings-content">
                        <div className="notifications-page_content">


                            {this.state.activeTabs['comments'] ? (!this.state.loadedhistory) ? (<div className="notifications-page_content_box"><LoadingSvg active={!this.state.loadedhistory} /></div>) : (
                                <div className="notifications-page_content_box">

                                    <div className="notifications-page_content_box_header">
                                        <h4> {getTranslatedPhrase(this.props.userLanguage, this.getRightTitle(this.state.commentsList.filter(v => v.checked === false).length, 'comment'))} </h4>
                                        <p className="small">30-Day History</p>
                                        {/* {(!this.state.commentsList.length) ? null :
                                            (<button className="button button-clear button-small" onClick={this.onClearComments}>{getTranslatedPhrase(this.props.userLanguage, 'Clear All')}</button>)} */}
                                    </div>

                                    <div className="notification-comments">
                                        {this.state.commentsList.map((v, i) => (<div key={i}
                                            className="notification-comments-item"
                                            style={!this.state.clearedComments && v.checked === false ? { borderBottom: '1px solid #778ca3' } : {}}
                                        >

                                            <img src={v.authorProfileImage || 'https://websitediag290.blob.core.windows.net/images/uploads/default/default-profile-image.jpg'} onError={addDefaultSingleSrc} alt={v.authorName} />

                                            <div className="notification-comments-item-content">
                                                <div className="notification-comments-item-content-date">
                                                    <strong>{!this.state.clearedComments && v.checked === false ? 'NEW - &nbsp;' : ''}</strong>
                                                    <span>{getTranslatedPhrase(this.props.userLanguage, 'On')} </span> {moment(v.activityDate).local().format('lll')}
                                                </div>

                                                <div className="notification-comments-item-content-text">
                                                    <strong><Link to={'/' + v.authorSlug}> {v.authorName}</Link></strong> said: "{(!v.comment) ? null : (v.comment)}" on your {getTranslatedPhrase(this.props.userLanguage, this.getItemType(v.itemType))}:
                                                    <strong><Link to={v.url + '#reviews'}> {v.snippet}</Link></strong>.
                                                </div>
                                            </div>
                                        </div>))}
                                    </div>
                                </div>) : null}

                            {this.state.activeTabs['followrequests'] ? (!this.state.loaded) ? (<div className="notifications-page_content_box"><LoadingSvg active={!this.state.loaded} /></div>) : (
                                <div className="notifications-page_content_box">
                                    <div className="notifications-page_content_box_header">
                                        <h4>{getTranslatedPhrase(this.props.userLanguage, 'You Have')} {this.state.followRequestsList.length} {getTranslatedPhrase(this.props.userLanguage, this.getRightEnding(this.state.followRequestsList.length))}</h4>
                                    </div>

                                    <div className="follow-requests_content">
                                        {!this.state.loaded ? (<LoadingSvg active={!this.state.loaded} />) : (
                                            this.state.followRequestsList.length !== 0 &&
                                            <div className="follow-requests_content_box">
                                                {this.state.followRequestsList.map((v, i) => (!v) ? null : (<div className="follow-requests_content_box_listing" key={i}>
                                                    <div className="follow-requests_content_box_listing_meta">
                                                        <span><img src={v.profileImage} alt={v.userName} /></span> <Link to={'/' + v.slug}> {v.userName}</Link>
                                                    </div>

                                                    {/* <p>{(!!v.comment) && (v.comment)}</p> */}

                                                    <div className="follow-requests_content_box_listing_buttons">
                                                        <button className="smallButton" onClick={() => this.onApproveFollowRequest(v.userId)} >{getTranslatedPhrase(this.props.userLanguage, 'Approve')}</button>
                                                        <button className="smallButton" onClick={() => this.onRemoveFollowRequest(v.userId)} >{getTranslatedPhrase(this.props.userLanguage, 'Delete')}</button>
                                                    </div>
                                                </div>))}
                                            </div>)}
                                    </div>
                                </div>) : null}


                            {this.state.activeTabs['likes'] ? (!this.state.loadedhistory) ? (<div className="notifications-page_content_box"><LoadingSvg active={!this.state.loadedhistory} /></div>) : (
                                <div className="notifications-page_content_box">
                                    <div className="notifications-page_content_box_header">
                                        <h4>{getTranslatedPhrase(this.props.userLanguage, this.getRightTitle(this.state.likesList.filter(v => v.checked === false).length, 'like'))}</h4>
                                        <p className="small">30-Day History</p>
                                        {/* {(!this.state.likesList.length) ? null :
                                            (<button className="button button-clear button-small" onClick={this.onClearLikes}>{getTranslatedPhrase(this.props.userLanguage, 'Clear All')}</button>)} */}
                                    </div>

                                    <div className="notification-comments">
                                        {!this.state.loaded ? (<LoadingSvg active={!this.state.loadedhistory} />) :
                                            this.state.likesList.map((v, i) => (

                                                <div key={i} className="notification-comments-item" style={!this.state.clearedLikes && v.checked === false ? { borderBottom: '1px solid #778ca3' } : {}}>
                                                    <img src={v.authorProfileImage || 'https://websitediag290.blob.core.windows.net/images/uploads/default/default-profile-image.jpg'} onError={addDefaultSingleSrc} alt={v.authorName} />

                                                    <div className="notification-comments-item-content">
                                                        <div className="notification-comments-item-content-date">
                                                            <strong>{!this.state.clearedLikes && v.checked === false ? 'NEW - &nbsp;' : ''}</strong>
                                                            <span>{getTranslatedPhrase(this.props.userLanguage, 'On')} </span> {moment(v.activityDate).local().format('lll')}
                                                        </div>

                                                        <div className="notification-comments-item-content-text">
                                                            <strong><Link to={'/' + v.authorSlug}> {v.authorName}</Link></strong> liked your {getTranslatedPhrase(this.props.userLanguage, this.getItemType(v.itemType))}:
                                                            <strong><Link to={v.url + '#reviews'}> {v.snippet}</Link></strong>.
                                                        </div>
                                                    </div>
                                                </div>))}
                                    </div>
                                </div>) : null}



                            {this.state.activeTabs['reviews'] ? (!this.state.loadedhistory) ? (<div className="notifications-page_content_box"><LoadingSvg active={!this.state.loadedhistory} /></div>) : (
                                <div className="notifications-page_content_box">
                                    <div className="notifications-page_content_box_header">
                                        <h4>{getTranslatedPhrase(this.props.userLanguage, this.getRightTitle(this.state.reviewsList.filter(v => v.checked === false).length, 'review'))}</h4>
                                        <p className="small">30-Day History</p>
                                        {/* {(!this.state.reviewsList.length) ? null :
                                            (<button className="button button-clear button-small" onClick={this.onClearReviews}>{getTranslatedPhrase(this.props.userLanguage, 'Clear All')}</button>)} */}
                                    </div>

                                    <div className="notification-comments">
                                        {!this.state.loadedhistory ? (<LoadingSvg active={!this.state.loadedhistory} />) :
                                            this.state.reviewsList.map((v, i) => (

                                                <div key={i} className="notification-comments-item" style={!this.state.clearedReviews && v.checked === false ? { borderBottom: '1px solid #778ca3' } : {}}>
                                                    <img src={v.authorProfileImage || 'https://websitediag290.blob.core.windows.net/images/uploads/default/default-profile-image.jpg'} onError={addDefaultSingleSrc} alt={v.authorName} />

                                                    <div className="notification-comments-item-content">
                                                        <div className="notification-comments-item-content-date">
                                                            <strong>{!this.state.clearedReviews && v.checked === false ? 'NEW - &nbsp;' : ''}</strong>
                                                            <span>{getTranslatedPhrase(this.props.userLanguage, 'On')} </span> {moment(v.activityDate).local().format('lll')}
                                                        </div>

                                                        <div className="notification-comments-item-content-text">
                                                            <strong><Link to={'/' + v.authorSlug}> {v.authorName}</Link></strong> reviewed your {getTranslatedPhrase(this.props.userLanguage, this.getItemType(v.itemType))}:
                                                            <strong><Link to={v.url}> {v.snippet}</Link></strong>.
                                                        </div>
                                                    </div>
                                                </div>))}
                                    </div>
                                </div>) : null}

                            {!!this.state.affiliateId && this.state.activeTabs['paidNotifications'] && (<div className="notifications-page_content_box">


                                <Specials userIsAffiliate={true}>
                                    <>
                                        {/* {(!this.state.specialsCount) ? null : (
                                            <div className="notifications-page_content_box_header">
                                                <h4 style={{ marginBottom: '0' }}>{this.state.specialsCount} {getTranslatedPhrase(this.props.userLanguage, 'Cash-in Ads Available')} </h4>
                                                <Link className="button button-clear button-small" to="/cash-in">Dashboard</Link>

                                            </div>)} */}
                                    </>
                                </Specials>


                            </div>)}

                        </div>
                    </div>
                </div>
            </div>
        </>);
    }
}


const mapStateToNotificationsProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
        userIsAuthenticated: state.userIsAuthenticated,
        loggedInUser: state.loggedInUser
    };
};

const mapDispatchToNotificationsProps = (
    dispatch
) => {
    return {
        addPayperviewRedirect: (c) => {
            dispatch({ type: actionType.PAYPERVIEW_REDIRECT, payperviewRedirect: c });
        },
    };
};

export default connect(mapStateToNotificationsProps, mapDispatchToNotificationsProps)(withRouter(Notifications));

//                            <button className={!this.state.activeTabs['shares']?'':'active'} onClick={(e) => this.setActiveTab('shares')}>({this.state.sharesList.length}) {getTranslatedPhrase(this.props.userLanguage,'Shares')} </button>


// {this.state.activeTabs['shares'] && (<div className="notifications-page_content_box">
// <h4>{getTranslatedPhrase(this.props.userLanguage,this.getRightTitle(this.state.sharesList.length,'share'))}</h4>

// {this.state.sharesList
// .map( (v,i) => (<div key={i} className="notifications-page_content_box_listitem"><Link to={'/'+v.authorSlug}>
// {v.authorName}</Link> {getTranslatedPhrase(this.props.userLanguage,'shared your')} {getTranslatedPhrase(this.props.userLanguage, this.getItemType(v.itemType))} <Link to={`/${v.itemType}/${v.category}/${v.slug}`}>{trimTitle(v.title,50)}</Link></div>))}

// {(!this.state.sharesList.length)?null:
// (<button className="button button-clear button-small mgt-sm" onClick={this.onClearShares}>{getTranslatedPhrase(this.props.userLanguage,'Clear Shares')}</button>)}
// </div>)}