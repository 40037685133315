import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Backdrop from '../../../uielements/Backdrop/Backdrop';
import actionType from '../../../../redux-store/action-type';
import { connect } from 'react-redux';
import { hostNameAndPort } from '../../../util/vars';
import Axios from 'axios';
import { getTranslatedPhrase } from '../../../util/lang';


class WelcomeModal extends Component {
    onClickCloseHandler = () => {
        let _data = new FormData();
        _data.append('user', this.props.loggedInUser.slug);
        let options = {
            url: `${hostNameAndPort}/api/admin/enable-account`,
            method: 'post',
            data: _data
        };
        Axios(options)
            .then(result => {
                let { data } = result;
                if (!data.error) {
                    // No errors
                }
            })
            .catch(err => {
                // Network error
            });
        this.props.onClickClose();
    }

    render() {
        if (!this.props.active || !this.props.loggedInUser) {
            return null;
        }
        return (<div className="modal-wrapper">
            <Backdrop show={this.props.active} clicked={this.onClickCloseHandler} />
            <div className="modal">
                <div className="modal_body">
                    <h4>{getTranslatedPhrase(this.props.userLanguage, 'Welcome back')}, {this.props.loggedInUser.firstName}!</h4>

                    <div className="modal_body_functions modal_body_functions_welcome-modal">
                        <Link onClick={this.onClickCloseHandler} to="/profile-update" className="modal_body_functions_welcome-modal_icon"><i className="fa-duotone fa-id-card" /><span>{getTranslatedPhrase(this.props.userLanguage, 'Update Profile')}</span></Link>
                        <Link onClick={this.onClickCloseHandler} to="/invite-friends" className="modal_body_functions_welcome-modal_icon"><i className="fal fa-users" /><span>{getTranslatedPhrase(this.props.userLanguage, 'Invite Friends')}</span></Link>
                        <Link onClick={this.onClickCloseHandler} to="/privacy-settings" className="modal_body_functions_welcome-modal_icon"><i className="fal fa-lock-alt" /><span>{getTranslatedPhrase(this.props.userLanguage, 'Privacy')}</span></Link>
                        <Link onClick={this.onClickCloseHandler} to="/subscription-settings" className="modal_body_functions_welcome-modal_icon"><i className="fa-duotone fa-user-gear" /><span>{getTranslatedPhrase(this.props.userLanguage, 'Settings')}</span></Link>
                    </div>
                </div>

                <span onClick={this.onClickCloseHandler} className="modal_close"><i className="fal fa-times" /></span>
            </div>
        </div>
        );
    }
}

const mapStateToWelcomeModalModalProps = (
    state,
    props
) => {
    return {
        active: state.welcomeModalActive,
        userLanguage: state.userLanguage,
        loggedInUser: state.loggedInUser
    };
};

const mapDispatchToWelcomeModalModalProps = (
    dispatch
) => {
    return {
        onClickClose: () => {
            dispatch({ type: actionType.WELCOME_MODAL, welcomeModalActive: false });
        }
    };
};

export default connect(mapStateToWelcomeModalModalProps, mapDispatchToWelcomeModalModalProps)(WelcomeModal);