import React from 'react';

function Coupon(props) {
    return (
        <div className="Coupon">
            {props.couponFeaturedImage}
            <p className="CouponSale"><span>{props.saleAmount}</span> OFF</p>
            <p className="CouponProduct">{props.saleProduct}</p>
            <p className="CouponBrand">by {props.saleBrand}</p>
            <p className="CouponCode">Use Code: <br/><span>{props.couponCode}</span></p>
            <p className="CouponDate">Valid {props.startDate} - {props.endDate}</p>
            <div className="CouponBrandLogo">{props.children}</div>
            <a className="button button-clear button-small dsp-in-blk" target="_blank" rel="noopener noreferrer" href={props.redeemURL || 'https://carzlook.com'}>Redeem</a>
        </div>
    );
}

export default Coupon;