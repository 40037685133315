import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';

const Cookie = props => {

    useEffect(() => {
        window.scrollTo(0,0);
    }, [])

    return (
        <div className="main-100 privacy-policy">

            <Helmet>
                <meta charSet="utf-8" />
                <title>Cookie Policy | CarzLook</title>
                <meta name="description" content="Your privacy is important to us." />
                <link rel="canonical" href="https://carzlook.com/cookie" />
            </Helmet>

                <div className="main-100-header">
                    <h1>Cookie Policy</h1>
                    <p>Your privacy is important to us.</p>
                </div>

                <div className="legal-text">

                    <p>Our Website uses ‘cookies’ (a small text file sent by your computer each time you visit our website, unique to your account or browser) or similar technologies. We use necessary, functional and analytical cookies. Necessary cookies are essential for the website to function properly. These cookies ensure basic functionalities and security features of the website, anonymously. Analytical cookies are used to understand how visitors interact with the website. These cookies help provide information on metrics the number of visitors, bounce rate, traffic source, etc. Functional cookies help to perform certain functionalities like sharing the content of the website on social media platforms, collect feedbacks, and other third-party features. Performance cookies are used to understand and analyse the key performance indexes of the website which helps in delivering a better user experience for the visitors.</p>

                    <p>The cookies used on our website are as follows.</p>

                    <table>
                        <tbody>
                            <tr>
                                <td>Cookie</td>
                                <td>Type</td>
                                <td>Description</td>
                                <td>Duration</td>
                            </tr>
                            <tr>
                                <td>_ga</td>
                                <td>Analytics</td>
                                <td>The _ga cookie, installed by Google Analytics, calculates visitor, session and campaign data and also keeps track of site usage for the site's analytics report. The cookie stores information anonymously and assigns a randomly generated number to recognize unique visitors.</td>
                                <td>2 years</td>
                            </tr>
                            <tr>
                                <td>_gid</td>
                                <td>Analytics</td>
                                <td>Installed by Google Analytics, _gid cookie stores information on how visitors use a website, while also creating an analytics report of the website's performance. Some of the data that are collected include the number of visitors, their source, and the pages they visit anonymously.</td>
                                <td>1 day</td>
                            </tr>
                            <tr>
                                <td>userLanguage</td>
                                <td>Functional</td>
                                <td>This cookie is used to store the user's language preference.</td>
                                <td>10 days 10 hours</td>
                            </tr>
                            <tr>
                                <td>__stripe_mid</td>
                                <td>Necessary</td>
                                <td>Stripe sets this cookie cookie to process payments.</td>
                                <td>1 year</td>
                            </tr>
                            <tr>
                                <td>__stripe_sid</td>
                                <td>Necessary</td>
                                <td>Stripe sets this cookie cookie to process payments.</td>
                                <td>30 minutes</td>
                            </tr>
                            <tr>
                                <td>fo_userCountry</td>
                                <td>Functional</td>
                                <td>No description</td>
                                <td>24 hours</td>
                            </tr>
                            <tr>
                                <td>fo_region</td>
                                <td>Functional</td>
                                <td>No description</td>
                                <td>24 hours</td>
                            </tr>
                            <tr>
                                <td>fo_lng</td>
                                <td>Functional</td>
                                <td>No description</td>
                                <td>24 hours</td>
                            </tr>
                            <tr>
                                <td>fo_lat</td>
                                <td>Functional</td>
                                <td>No description</td>
                                <td>24 hours</td>
                            </tr>
                            <tr>
                                <td>m</td>
                                <td>Other</td>
                                <td>No description</td>
                                <td>2 years</td>
                            </tr>
                            <tr>
                                <td>_gat</td>
                                <td>Performance</td>
                                <td>This cookie is installed by Google Universal Analytics to restrain request rate and thus limit the collection of data on high traffic sites.</td>
                                <td>1 minute</td>
                            </tr>
                        </tbody>
                    </table>


                    <p>For further information on how we use, store and keep your personal data secure, read our <Link to="/privacy">Privacy Policy</Link>.</p>
               
                </div> 

        </div>
    );
}

export default Cookie;